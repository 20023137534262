import React from 'react'
import {Stack, HStack, Box, Flex, Text, Input, Select, Heading, Checkbox,} from '@chakra-ui/react'
import {InputLabel} from '../../../../../components/Theme'
import NavigateModal from '../NavigateModal'
import {ReactComponent as DollarIcon} from "../../../../../assets/Icons/dollarIcon.svg";
import {ReactComponent as PendingIcon} from "../../../../../assets/Icons/pendingIcon.svg";
import {ReactComponent as ArrowDownIcon} from "../../../../../assets/downArrow.svg";
import CustomSelectMultiple from "../../../Parts/CustomSelectMultiple";

const View = ({
                  setType,
                  setCertifications,
                  customPricing,
                  setCustomPricing,
                  validate,
                  handleSelect,
                  certifications,
                  selectedItems,
                  filters,
                  lampDisabled,
                  setFilters,
                  description,
                  lamp,
                  setLamp,
                  setSelectedItems,
                  fetchedDataLamp,
                  create,
                  isOpen,
                  setIsOpen,
                  refetch,
                  selectAll,
                  lampAirtable,
                  handleSelectAll,
                  setLampAirtable,
              }) => (
    <Stack spacing="5">
        <NavigateModal
            label={description}
            leftAction={() => {
                create()
                setType('fixture')
            }}
            rightAction={() => {
                create()
                setType('power')
            }}
            left="Fixture"
            right="Power Supply"/>
        <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
            <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
            <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                 color="#2C628D" as="span">
                lamp details
            </Box>
        </HStack>
        <HStack spacing="5" alignItems="flex-end">
            <InputLabel
                height='46px'
                maxW="203px"
                type="number"
                value={lamp?.recomendedLampQty ? lamp?.recomendedLampQty : lamp?.lampPerFixture}
                onChange={(e) => setLamp({
                    ...lamp,
                    recomendedLampQty: e.target.value,
                })}
                pattern="[0-9]*"
                label="QTY/Fixture"/>
            <Box position='relative' width='100%' height='46px'>
                <Flex
                    alignItems='center'
                    onClick={() => {
                        if (lampDisabled) return
                        setIsOpen(!isOpen)
                    }}
                    bg='#EEEEEE'
                    cursor='pointer'
                    border='1px solid #ccc'
                    borderBottom='none'
                    position='realtive'
                    borderBottomLeftRadius='6px'
                    borderBottomRightRadius='6px'
                    fontSize='18px'
                    p='10px 30px'>
                    {lampAirtable?.length > 0 ? lampAirtable.map(item => item.fields["Product Description"]).join(', ') : "No Change"}
                    <ArrowDownIcon style={{position: 'absolute', right: '10px', top: '18px'}}/>
                </Flex>
                {isOpen && (
                    <ul style={{
                        position: 'absolute',
                        top: '99%',
                        left: '0',
                        right: '0',
                        border: '1px solid #ccc',
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                        backgroundColor: 'white',
                        zIndex: 1000,
                        maxHeight: '500px',
                        overflowY: 'auto'
                    }}>
                        {customPricing.lamp && (
                            <li style={{
                                padding: '8px 8px 0 8px',
                                cursor: 'pointer',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Checkbox
                                    _checked={{
                                        "& .chakra-checkbox__control": {
                                            background: "#FFE68D",
                                            boxShadow: 'none',
                                            borderColor: 'black',
                                            outline: 'none'
                                        }
                                    }}
                                    _hover={{
                                        "& .chakra-checkbox__control": {
                                            background: "#FFE68D",
                                            boxShadow: 'none',
                                            borderColor: 'black',
                                            outline: 'none'
                                        }
                                    }}
                                    _focus={{
                                        "& .chakra-checkbox__control": {
                                            background: "#FFE68D",
                                            borderColor: 'black',
                                            boxShadow: 'none'
                                        }
                                    }}
                                    _focusVisible={{
                                        "& .chakra-checkbox__control": {outline: 'none', boxShadow: 'none'}
                                    }}
                                    iconColor='black'
                                    isChecked={selectAll}
                                    mr='8px'
                                    size='lg'
                                    borderColor='#222222'
                                    onChange={handleSelectAll}>
                                    <Heading m={0} fontSize='14px'>Select All</Heading>
                                </Checkbox>
                            </li>
                        )}
                        {fetchedDataLamp?.filter((item) => validate(item) === true).map(item => (
                            <li key={item.id} style={{
                                padding: customPricing.lamp ? '8px 8px 0 8px' : '8px 30px 0 30px',
                                cursor: 'pointer',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {
                                    customPricing.lamp ? (
                                        <>
                                            {item.fields['Bid Status'] === 'pending' || item.fields['Bid Status'] === 'accepted' ? (
                                                <Flex
                                                    mr='8px'
                                                    border='2px solid black'
                                                    alignItems='center' justifyContent='center' borderRadius='2px'
                                                    bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                                    width='20px' height='20px' >
                                                    {item.fields['Bid Status'] === 'pending' ? (
                                                        <PendingIcon />
                                                    ) : (
                                                        <DollarIcon />
                                                    )}
                                                </Flex>
                                            ) :(
                                                <Checkbox
                                                    _checked={{
                                                        "& .chakra-checkbox__control": {
                                                            background: "#FFE68D",
                                                            boxShadow: 'none',
                                                            borderColor: 'black'
                                                        }
                                                    }}
                                                    _hover={{
                                                        "& .chakra-checkbox__control": {
                                                            background: "#FFE68D",
                                                            boxShadow: 'none',
                                                            borderColor: 'black'
                                                        }
                                                    }}
                                                    size='lg'
                                                    iconColor='black'
                                                    borderColor='#222222'
                                                    isChecked={selectedItems.includes(item.id)}
                                                    onChange={() => handleSelect(item.id)}
                                                    mr='8px'>
                                                </Checkbox>
                                            )}
                                            <Text as='span'
                                                  onClick={(e) => {
                                                      setLampAirtable((prev) => {
                                                          if (prev.some(lamp => lamp.id === item.id)) {
                                                              return prev.filter(lamp => lamp.id !== item.id);
                                                          } else {
                                                              return [item];
                                                          }
                                                      })
                                                  }}
                                                  fontSize='18px'>{item.fields["Product Description"]}</Text>&nbsp;
                                            <Text as='span' onClick={(e) => setLampAirtable([item])}
                                                  fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$'+item.fields["SU PPU ($) Custom"] : ''}</Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text
                                                onClick={(e) => {
                                                    setLampAirtable((prev) => {
                                                        if (prev.some(lamp => lamp.id === item.id)) {
                                                            return prev.filter(lamp => lamp.id !== item.id);
                                                        } else {
                                                            return [item];
                                                        }
                                                    })
                                                }}
                                                as='span'
                                                fontSize='18px'>{item.fields["Product Description"]}</Text>&nbsp;
                                            <Text as='span'
                                                  fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$'+item.fields["SU PPU ($) Custom"] : item.fields["Price Per Unit ($)"]}</Text>
                                        </>
                                    )
                                }
                                <Flex
                                    display={customPricing.lamp && (!item.fields["Price Per Unit ($)"] || item.fields["Price Per Unit ($)"] === '$0.00') ? 'flex' : 'none'}
                                    alignItems='center' justifyContent='center' borderRadius='2px'
                                    bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                    width='20px' height='20px' position='absolute' right='30px' top='8px'>
                                    {item.fields['Bid Status'] === 'pending' ? (
                                        <PendingIcon />
                                    ) : item.fields['Bid Status'] === 'accepted' ? (
                                        <DollarIcon />
                                    ) :(
                                        '?'
                                    )}
                                </Flex>
                            </li>
                        ))}
                    </ul>
                )}
            </Box>
        </HStack>
        <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
            <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
            <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                 color="#2C628D" as="span">
                filters
            </Box>
        </HStack>
        <HStack justifyContent='space-between'>
            <Box/>
            <HStack spacing='7'>
                <Heading fontSize='14px' textAlign='center' m={0} width='360px'>MINIMUM</Heading>
                <Heading fontSize='14px' textAlign='center' m={0} width='360px'>MAXIMUM</Heading>
            </HStack>
        </HStack>
        <HStack spacing='5' justifyContent='space-between'>
            <Heading color='#2C628D' fontWeight='400' fontSize='14px' m={0}>
                Wattage
            </Heading>
            <HStack spacing='7'>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedWattageMinimum}
                    onChange={(e) => {
                        setFilters({...filters, wattageMin: e.target.value})
                        setLamp({...lamp, lampRecomendedWattageMinimum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
                <Input
                    fontSize='18px'
                    height='46px'
                    value={lamp?.lampRecomendedWattageMaximum}
                    onChange={(e) => {
                        setFilters({...filters, wattageMax: e.target.value})
                        setLamp({...lamp, lampRecomendedWattageMaximum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
            </HStack>
        </HStack>
        <HStack justifyContent='space-between' spacing='5'>
            <Heading m={0} color='#2C628D' fontSize='14px' fontWeight='400'>Lumens</Heading>
            <HStack spacing='7'>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedLumensMinimum}
                    onChange={(e) => {
                        setFilters({...filters, lumensMin: e.target.value})
                        setLamp({...lamp, lampRecomendedLumensMinimum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedLumensMaximum}
                    onChange={(e) => {
                        setFilters({...filters, lumensMax: e.target.value})
                        setLamp({...lamp, lampRecomendedLumensMaximum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
            </HStack>
        </HStack>
        <HStack justifyContent='space-between' spacing='5'>
            <Heading m={0} color='#2C628D' fontSize='14px' fontWeight='400'>Color</Heading>
            <HStack spacing='7'>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedColorMinimum}
                    onChange={(e) => {
                        setFilters({...filters, colorMin: e.target.value})
                        setLamp({...lamp, lampRecomendedColorMinimum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedColorMaximum}
                    onChange={(e) => {
                        setFilters({...filters, colorMax: e.target.value})
                        setLamp({...lamp, lampRecomendedColorMaximum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
            </HStack>
        </HStack>
        <HStack justifyContent='space-between' spacing='5'>
            <Heading m={0} color='#2C628D' fontSize='14px' fontWeight='400'>Price</Heading>
            <HStack spacing='7'>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedPriceMinimum}
                    onChange={(e) => {
                        setFilters({...filters, priceMin: e.target.value})
                        setLamp({...lamp, lampRecomendedPriceMinimum: e.target.value})
                    }}
                    width='360px'
                    border='1px solid #A9A9A9 !important'/>
                <Input
                    fontSize='18px'
                    height='50px'
                    value={lamp?.lampRecomendedPriceMaximum}
                    onChange={(e) => {
                        setFilters({...filters, priceMax: e.target.value})
                        setLamp({...lamp, lampRecomendedPriceMaximum: e.target.value})
                    }}
                    border='1px solid #A9A9A9 !important'
                    width='360px'/>
            </HStack>
        </HStack>
        <HStack spacing='73px' justifyContent='space-between'>
            <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                Certification
            </Heading>
            <CustomSelectMultiple
                width='748px'
                value={certifications}
                setValue={setCertifications}
                options={["UL Listed", "DLC Approved","Energy Star","FCC","ETL","RoHS","CE Marketing","Other"]}
            />
        </HStack>
        <HStack justifyContent='space-between' spacing='5'>
            <Heading m={0} color='#2C628D' fontSize='14px' fontWeight='400'>Dimmability</Heading>
            <Select
                fontSize='18px'
                height='50px'
                value={lamp?.lampRecomendedDimmability}
                onChange={(e) => {
                    setFilters({...filters, dimmability: e.target.value})
                    setLamp({...lamp, lampRecomendedDimmability: e.target.value})
                }}
                placeholder='Select'
                width='748px'
                border='1px solid #A9A9A9 !important'>
                <option value="None">None</option>
                <option value="1% Dimmable">1% Dimmable</option>
                <option value="5% Dimmable">5% Dimmable</option>
                <option value="10% Dimmable">10% Dimmable</option>
                <option value="Leading Edge (TRIAC)">Leading Edge (TRIAC)</option>
                <option value="Trailing Edge (Electronic)">Trailing Edge (Electronic)</option>
                <option value="1-10V Dimmable">1-10V Dimmable</option>
                <option value="0-10V Dimmable">0-10V Dimmable</option>
            </Select>
        </HStack>
        <HStack spacing='5' justifyContent='space-between'>
            <HStack spacing='60px'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Show Custom <br/>Price Options
                </Heading>
                <Checkbox
                    _checked={{
                        "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                    }}
                    _hover={{
                        "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                    }}
                    iconColor='black'
                    onChange={(e) => {
                        refetch()
                        if (!e.target.isChecked) {
                            setSelectedItems([])
                            setLampAirtable([])
                        }
                        setCustomPricing({...customPricing, lamp: !customPricing.lamp})
                    }}
                    isChecked={customPricing.lamp} color='#A9A9A9' width='20px' height='20px'
                    borderColor='#2C628D'>
                    Yes
                </Checkbox>
            </HStack>
            <HStack spacing='40px' width='40%'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Buy American
                </Heading>
                <Checkbox
                    _checked={{
                        "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                    }}
                    _hover={{
                        "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                    }}
                    iconColor='black'
                    color='#A9A9A9' width='20px' height='20px' borderColor='#2C628D'>
                    Yes
                </Checkbox>
            </HStack>
        </HStack>
    </Stack>
)

export default View

import {useEffect, useState} from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Table,
    Tabs,
    Tbody,
    Heading,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import styles from './styles'
import {useGetUser, useListActivitys, useListNotifications, useUpdateNotification, useUser} from '../../core/hooks'
import SingleNotification from "../Notifications/Notification";
import moment from "moment/moment";
import {ReactComponent as RightArrow} from "../../assets/arrow_right.svg";
import {useHistory} from "react-router-dom";
import httpRequest from "../../core/services/http-request";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import {ReactComponent as LeftIcon} from "../../assets/leftIconShippingBlack.svg";
import {ReactComponent as RightIcon} from "../../assets/rightIconShippingBlack.svg";
import MessageModal from "../Notifications/MessageModal";
import ChatModal from "./ChatModal";
import {ACTIVITY_ENUM} from "../../constants/activityTypes";
import {S3_BUCKET} from "../../constants";
import {AU, EU, REU, SA, SU, SUPERADMIN} from "../../constants/user/userEnum";
import mainStyles from "../../styles/mainStyles";
import {SearchComponent} from "../../components/Theme";

// Changing logics ...

const RecentActivity = () => {
    const {user, setUser} = useUser()
    const {post} = httpRequest()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenChat, onOpen: onOpenChat, onClose: onCloseChat} = useDisclosure()
    const [all, setAll] = useState([])
    const [loading, setLoading] = useState(false)
    const {getUser} = useGetUser()
    const [notifViewMessage, setNotifViewMessage] = useState('')
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [offset, setOffset] = useState(1)
    const {data: notifResponse, listNotifications} = useListNotifications()
    const {data: activityResponse, listActivitys} = useListActivitys({
        userId: user.id,
        companyId: user.companyId ?? '',
        page: page,
        search: search,
    })
    const [notifications, setNotifications] = useState()
    const [chatInfo, setChatInfo] = useState()
    const {mutate: updateNotification} = useUpdateNotification()
    const history = useHistory()
    const theme = createTheme();

    useEffect(() => {
        listActivitys()
    }, [page])

    const viewProject = (e) => {
        const {post} = httpRequest()
        const id = e.actionId
        setLoading(true)
        post('get-project', {id}).then((res) => {
            setLoading(false)
            history.push(`/project/${res?.data?.data[0]?.project?.id}`)
        })
    }

    const viewCompany = (e) => {
        history.push(`/companies/${e.actionId}`)
    }

    const viewMessages = (e) => {
        setChatInfo(e)
        onOpenChat()
    }

    useEffect(() => {
        if (!activityResponse) return
        let activites = Object.values(activityResponse?.data?.data['items'])
        setAll(activites)
    }, [activityResponse])

    const getUse = () => {
        getUser(
            localStorage.getItem("token"),
            {
                onSuccess: (userResponse) => {
                    setUser(userResponse.data.data)
                },
            },
        )
    }

    useEffect(() => {
        if (!user) return
        setNotifications(user?.notificationReceiver)
        const notSeen = user?.notificationReceiver?.filter((item) => item.seen === false)
        if (notSeen.length > 0) {
            notSeen.forEach((item) => {
                updateNotification({
                        id: item.id,
                        seen: true,
                    },
                    {
                        onSuccess: (e) => {
                            setNotifications(e.data.data[0].notificationReceiver)
                            getUse()
                        }
                    })
            })
        }
    }, [user])

    useEffect(() => {
        if (!notifResponse) return
        setNotifications(notifResponse?.data?.data)
        const notSeen = notifResponse?.data?.data?.filter((item) => item?.seen === false)
        if (notSeen?.length > 0) {
            notSeen.forEach((item) => {
                updateNotification({
                        id: item.id,
                        seen: true,
                    },
                    {
                        onSuccess: () => {
                            listNotifications()
                            getUse()
                        }
                    })
            })
        }
    }, [notifResponse])


    const downloadTemplate = (res) => {
        post(`get-file/${res}`).then((res) => {
            const newName = res.data.data?.files?.name || 'Focus-Earth-Template.xlsx';
            const url = S3_BUCKET + res.data.data.name;
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    const objectURL = URL.createObjectURL(blob);

                    a.href = objectURL;
                    a.download = newName;

                    document.body.appendChild(a);
                    a.click();

                    URL.revokeObjectURL(objectURL);
                    document.body.removeChild(a);
                })
        })
    }

    useEffect(() => {
        if (search?.length > 1) {
            setTimeout(() => {
                listActivitys({
                    userId: user.id,
                    companyId: user.companyId ?? '',
                    page: page,
                    search: search,
                })
            }, 500)
        } else {
            listActivitys({
                userId: user.id,
                companyId: user.companyId ?? '',
                page: page,
                search: search,
            })
        }
    }, [search])

    return (
        <>
            <Navbar
                title="Recent Activity"
                children={<SearchComponent setSearch={setSearch}/>}/>
            <ChatModal
                getUse={getUse}
                isOpen={isOpenChat}
                onClose={onCloseChat}
                message={chatInfo}/>
            <MessageModal
                getUse={getUse}
                isOpen={isOpen}
                onClose={onClose}
                message={notifViewMessage}/>
            <Tabs
                css={mainStyles.container}
                display='flex'
                justifyContent='space-between'>
                <Box p='50px 50px 0 50px'>
                    <Heading
                        fontWeight='600'
                        fontSize='3xl'
                        mt={0}
                        pl={4}
                        position='relative'
                        color='#2C628D'>
                        Notifications
                        {notifications?.filter((item) => item.seen !== true).length > 0 && (
                            <Box position='absolute' display='inline-flex' bg="#FF3636" alignItems='center'
                                 height="20px" width="20px" ml='6px' justifyContent='center' fontSize='10px'
                                 color='white' borderRadius="100%">
                                {notifications?.filter((item) => item.seen !== true).length}
                            </Box>)
                        }
                    </Heading>
                    <Table sx={{'&': {borderCollapse: 'separate', borderSpacing: '0 20px'}}} variant="simple">
                        <Tbody>
                            {notifications?.sort((a: any, b: any) => moment.utc(b.createdAt.date).diff(moment.utc(a.createdAt.date))).map((product: any) => (
                                <SingleNotification
                                    getUse={getUse}
                                    setNotifViewMessage={setNotifViewMessage}
                                    onOpen={onOpen}
                                    key={product.id}
                                    product={product}/>
                            ))}
                        </Tbody>
                    </Table>
                    <Heading
                        fontWeight='600'
                        ml={4}
                        pt={2}
                        mt={0}
                        borderTop='2px solid #2C628D'
                        fontSize='3xl'
                        color='#2C628D'>
                        Recent Activity
                    </Heading>
                    <Flex alignItems='center' pl={5} mt={5} height='57px' justifyContent='space-between' border="none">
                        <Heading width='25%'  fontSize="xl" fontWeight="600" m={0}>
                            Where
                        </Heading>
                        <Heading width='25%' fontSize="xl" fontWeight="600" m={0}>
                            Who
                        </Heading>
                        <Heading width='25%' fontSize="xl" fontWeight="600" m={0}>
                            What
                        </Heading>
                        <Heading width='25%' fontSize="xl" fontWeight="600" m={0}>
                            When
                        </Heading>
                        <Text width='25%' fontSize="xl" fontWeight="600" m={0}>
                            How
                        </Text>
                    </Flex>
                    {
                        all?.sort((a: any, b: any) => moment.utc(b[0].createdAt.date).diff(moment.utc(a[0].createdAt.date))).map((item: any, index) => (
                            <Accordion allowToggle key={index}>
                                <AccordionItem disabled border='none'>
                                    {({isExpanded}) => (
                                        <>
                                            <AccordionButton _focus='' borderRadius='10px 10px 0 0'
                                                             border={isExpanded && item.length > 1 ? '2px solid #A9A9A9' : ''}
                                                             p={0}>
                                                <Flex pl={5} borderRadius='10px 10px 0 0'
                                                      mb={isExpanded && item.length > 1 ? 0 : 5} alignItems='center'
                                                      minH='57px' width='100%' justifyContent='space-between'
                                                      key={item[0].name + item[0].createdAt.date} bg="white.100"
                                                      boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)">
                                                    <Text textAlign='start' width='25%'>{item[0].projectTitle}</Text>
                                                    <Flex alignItems='center' textAlign='start' width='25%'>
                                                        {item.length > 1 ?
                                                            item.every(a => a.userName === item[0].userName) ? (
                                                                <>
                                                                    <Text mr={2} width='15px' height='15px'
                                                                          border='1px solid black' borderRadius='100%'
                                                                          bg={
                                                                              item[0].userType === REU ? '#C956FF' :
                                                                                  item[0].userType === SU ? '#FFD339' :
                                                                                      item[0].userType === SA ? '#A6F484' :
                                                                                          item[0].userType === AU ? '#ACDAFF' :
                                                                                              item[0].userType === EU ? '#FF8660' :
                                                                                                  'red'}/>
                                                                    <Text>
                                                                        {item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && item[0].user?.type === AU ? (
                                                                            item[0].user.firstName + ' ' + item[0].user.lastName
                                                                        ) : item[0].action === ACTIVITY_ENUM.COMPANY_EDITED ? (item[0].action.userName)
                                                                            : item[0].action === ACTIVITY_ENUM.PROJECT_JOINED ? item[0].senderName
                                                                                : item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && item[0].user?.type !== AU ? item[0].senderName
                                                                                    : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? item[0].senderName
                                                                                        : item[0].userName
                                                                        }
                                                                    </Text>
                                                                </>
                                                            ) : <Text>Multiple Users</Text>
                                                            : (
                                                                <>
                                                                    <Text mr={2} width='15px' height='15px'
                                                                          border='1px solid black' borderRadius='100%'
                                                                          bg={
                                                                              item[0].userType === REU ? '#C956FF' :
                                                                                  item[0].userType === SU ? '#FFD339' :
                                                                                      item[0].userType === SA ? '#A6F484' :
                                                                                          item[0].userType === AU ? '#ACDAFF' :
                                                                                              item[0].userType === EU ? '#FF8660' :
                                                                                                  'red'}/>
                                                                    <Text>
                                                                        {item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && item[0].user?.type === AU ? (
                                                                            item[0].user.firstName + ' ' + item[0].user.lastName
                                                                        ) : item[0].action === ACTIVITY_ENUM.COMPANY_EDITED ? (item[0].action.userName)
                                                                            : item[0].action === ACTIVITY_ENUM.PROJECT_JOINED ? item[0].senderName
                                                                                : item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && item[0].user?.type !== AU ? item[0].senderName
                                                                                    : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? item[0].senderName
                                                                                        : item[0].userName
                                                                        }
                                                                    </Text>
                                                                </>)}
                                                    </Flex>
                                                    <Text textAlign='start' width='25%' py={3}>
                                                        {
                                                            item.every((obj, index, array) => obj.action === array[0].action) ?
                                                                item[0].action === ACTIVITY_ENUM.COMPANY_CREATED ? (
                                                                    'Created Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROJECT_CREATED ? (
                                                                    'Created Project'
                                                                ) : item[0].action === ACTIVITY_ENUM.COMPANY_EDITED ? (
                                                                    'Edited Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROJECT_EDITED ? (
                                                                    item[0]?.projectUpdateLog?.updateLog ? `Edited ${Object.keys(item[0]?.projectUpdateLog?.updateLog)?.map(key => key === 'Address2' ? 'Second Address' : key)} details` : 'Edited Project'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED ? (
                                                                    `Approved proposal ${item[0]?.projectTitle}`
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || item[0].action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? (
                                                                    `Confirmed information`
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_CREATED ? (
                                                                    'Created Proposal'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_EDITED ? (
                                                                    'Edited Proposal'
                                                                ) : item[0].action === ACTIVITY_ENUM.CREATED_BID ? (
                                                                    'Created Bid'
                                                                ) : item[0].action === ACTIVITY_ENUM.UPDATED_BID ? (
                                                                    'Updated Bid'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_DONE ? (
                                                                    'Proposal Done'
                                                                ) : item[0].action === ACTIVITY_ENUM.CREATED_ORDER ? (
                                                                    'Created Order'
                                                                ) : item[0].action === ACTIVITY_ENUM.CREATED_SHIPPING_SU ? (
                                                                    'Created Shipping'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROJECT_JOINED ? (
                                                                    'Joined Project'
                                                                ) : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED ? (
                                                                    'Joined Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.USER_CREATED ? (
                                                                    'Signed up as a new ' + item[0].userType.toUpperCase()
                                                                ) : item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? (
                                                                    `Invited ${item[0].userName} to join!`
                                                                ) : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? (
                                                                    'Approved Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.COMPANY_DECLINED_BY_ADMIN ? (
                                                                    'Declined Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.MESSAGE_CREATED ? (
                                                                    'Sent Message'
                                                                ) : item[0].action === ACTIVITY_ENUM.BID_OPENED ? (
                                                                    'Opened bidding'
                                                                ) : item[0].action === ACTIVITY_ENUM.BID_CLOSED ? (
                                                                    'Closed bidding'
                                                                ) : item[0].action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? (
                                                                    'Uploaded Template'
                                                                ) : item[0].action === ACTIVITY_ENUM.BID_ACCEPTED ? (
                                                                    'Approved bid'
                                                                ) : item[0].action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY || item[0].action === ACTIVITY_ENUM.UPLOADED_FILE ? (
                                                                    'Uploaded a file'
                                                                ) : item[0].action === ACTIVITY_ENUM.SHIPPING_DELIVERED ? (
                                                                    'Marked as delivered'
                                                                ) : item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE ? (
                                                                    'Requested product pricing'
                                                                )  : item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU ? (
                                                                    'Send product pricing'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROJECT_STATUS_CHANGED ? (
                                                                    `Changed project status to ${item[0]?.project?.status}`
                                                                ) : ('')
                                                                : 'Multiple Actions'
                                                        }
                                                    </Text>
                                                    <Text textAlign='start' width='25%'>
                                                        {moment(item[0].createdAt.date).format('MMM DD, YYYY')}
                                                    </Text>
                                                    <Text textAlign='start' width='25%' p={0}>
                                                        <Button
                                                            mr={10}
                                                            rightIcon={(<RightArrow width="8px" height="8px"/>)}
                                                            fontWeight='600'
                                                            height="30px"
                                                            disabled={!!loading}
                                                            onClick={() =>
                                                                item[0].action === ACTIVITY_ENUM.COMPANY_DECLINED_BY_ADMIN ? '' :
                                                                item[0].action === ACTIVITY_ENUM.COMPANY_CREATED || item[0].action === ACTIVITY_ENUM.COMPANY_EDITED ? viewCompany(item[0])
                                                                    : item[0].action === ACTIVITY_ENUM.MESSAGE_CREATED ? viewMessages(item[0])
                                                                        : item[0].action === ACTIVITY_ENUM.PROPOSAL_DONE  || ((item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU)  && user?.type===SUPERADMIN) ? history.push(`/projects/proposal/${item[0]?.projectTitle}/${item[0]?.project?.id}/${item[0]?.actionId}`)
                                                                            : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN && user?.type === SUPERADMIN ? history.push(`/companies/${item[0].actionId}`)
                                                                            : item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED || (item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN && user?.type !== SUPERADMIN)? history.push({
                                                                                    pathname: 'profile',
                                                                                    search: '?company=MyCompany'
                                                                                })
                                                                                : (item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU) && user?.type === SU ? history.push(`/project-supplier/${item[0]?.project?.id}`)
                                                                                : item[0].action === ACTIVITY_ENUM.USER_CREATED || item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? history.push(`users/${item[0].actionId}`)
                                                                                    : (item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || item[0].action === ACTIVITY_ENUM.CREATED_ORDER || item[0].action === ACTIVITY_ENUM.CREATED_SHIPPING_SU) && item[0].userType === 'Reu' ? history.push(`/orders/estimate/reu/${item[0]?.estimateReu?.id}/1`)
                                                                                        : (item[0].action === ACTIVITY_ENUM.SHIPPING_DELIVERED && item[0].userType === REU) ? history.push(`/orders/estimate/reu/${item[0]?.estimateReu?.id}/3`)
                                                                                            : (item[0].action === ACTIVITY_ENUM.SHIPPING_DELIVERED && item[0].userType === SU) ? history.push(`/orders/estimate/su/${item[0]?.estimate?.id}/3`)
                                                                                                : item[0].action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY ? history.push({
                                                                                                        pathname: 'profile',
                                                                                                        search: '?company=MyCompany'
                                                                                                    })
                                                                                                    : item[0].action === ACTIVITY_ENUM.UPLOADED_FILE ? history.push('/upload')
                                                                                                        : item[0].action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? downloadTemplate(item[0]?.actionId)
                                                                                                            : item[0].action === ACTIVITY_ENUM.BID_OPENED || item[0].action === ACTIVITY_ENUM.BID_CLOSED || item[0].action === ACTIVITY_ENUM.BID_ACCEPTED || item[0].action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? history.push(`/project/${item[0].actionId}`)
                                                                                                                : (item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED || item[0].action === ACTIVITY_ENUM.CREATED_ORDER || item[0].action === ACTIVITY_ENUM.CREATED_SHIPPING_SU) && item[0].userType === SU ? history.push(`/orders/estimate/su/${item[0]?.estimate?.id}/1`)
                                                                                                                    : viewProject(item[0])
                                                            }
                                                            color='#222222'
                                                            width="150px"
                                                            backgroundColor="#FFE68D">
                                                            {
                                                                item[0].action === ACTIVITY_ENUM.COMPANY_CREATED || item[0].action === ACTIVITY_ENUM.COMPANY_EDITED || item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED || item[0].action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? (
                                                                    'View Company'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || item[0].action === ACTIVITY_ENUM.CREATED_ORDER || item[0].action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? (
                                                                    'View Estimate'
                                                                ) : item[0].action === ACTIVITY_ENUM.MESSAGE_CREATED ? (
                                                                    'View Thread'
                                                                ) : item[0].action === ACTIVITY_ENUM.CREATED_SHIPPING_SU || item[0].action === ACTIVITY_ENUM.SHIPPING_DELIVERED ? (
                                                                    'View Shipping'
                                                                ) : item[0].action === ACTIVITY_ENUM.USER_CREATED || item[0].action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? (
                                                                    'View Profile'
                                                                ) : item[0].action === ACTIVITY_ENUM.PROPOSAL_DONE || item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || item[0].action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU ? (
                                                                    'View Proposal'
                                                                ) : item[0].action === ACTIVITY_ENUM.BID_ACCEPTED ? (
                                                                    'View Details'
                                                                ) : item[0].action === ACTIVITY_ENUM.UPLOADED_FILE || item[0].action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY ? (
                                                                    'View File'
                                                                ) : item[0].action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? (
                                                                    'View Template'
                                                                ) : (
                                                                    'View Project'
                                                                )
                                                            }
                                                        </Button>
                                                        <AccordionIcon display={item.length > 1 ? 'inline' : 'none'}
                                                                       cursor='pointer'/>
                                                    </Text>
                                                </Flex>
                                            </AccordionButton>
                                            <AccordionPanel bg="#F5F5F5" p={0} borderRadius='0 0 10px 10px'
                                                            mb={isExpanded && item.length > 1 ? 5 : 0}
                                                            css={styles.accordionPanel}
                                                            border={isExpanded && item.length > 1 ? '2px solid #A9A9A9' : ''}>
                                                {(() => {
                                                    let arrayOfObjects = item.length === 1 ? item.slice(1) : item
                                                    // const uniqueActions = [...new Set(arrayOfObjects.map(obj => obj.action))];
                                                    const filteredArray = arrayOfObjects.filter((obj, index, self) =>
                                                        index === self.findIndex((t) => t.action === obj.action && t.userName === obj.userName)
                                                    );
                                                    return (
                                                        arrayOfObjects?.map((e, index) => (
                                                            <Flex pl={5} alignItems='center' height='57px'
                                                                  borderBottom='1px solid #A9A9A9' width='100%'
                                                                  justifyContent='space-between' key={index}
                                                                  bg="#F5F5F5"
                                                                  boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)">
                                                                <Text textAlign='start' width='25%'>
                                                                    {e?.projectTitle}
                                                                </Text>
                                                                <Flex alignItems='center' textAlign='start' width='25%'>
                                                                    <Text mr={2}
                                                                          bg={
                                                                              e.userType === REU ? '#C956FF' :
                                                                                  e.userType === SU ? '#FFD339' :
                                                                                      e.userType === SA ? '#A6F484' :
                                                                                          e.userType === AU ? '#ACDAFF' :
                                                                                              e.userType === EU ? '#FF8660' :
                                                                                                  'red'
                                                                          }
                                                                          width='15px'
                                                                          height='15px'
                                                                          border='1px solid black'
                                                                          borderRadius='100%'/>
                                                                    {e.action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && e.userType === AU ? (
                                                                        e.user.firstName + ' ' + e.user.lastName
                                                                    ) : e.action === ACTIVITY_ENUM.COMPANY_EDITED ? (
                                                                        e.userName
                                                                    ) : e.action === ACTIVITY_ENUM.PROJECT_JOINED ? e.senderName : e.action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM && e.userType !== AU ? e.senderName : e.userName}
                                                                </Flex>
                                                                <Text textAlign='start' width='25%'>
                                                                    {
                                                                        e.action === ACTIVITY_ENUM.COMPANY_CREATED ? (
                                                                            'Created Company'
                                                                        ) : e.action === ACTIVITY_ENUM.PROJECT_CREATED ? (
                                                                            'Created Project'
                                                                        ) : e.action === ACTIVITY_ENUM.COMPANY_EDITED ? (
                                                                            'Edited Company'
                                                                        ) : e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED ? (
                                                                            `Approved proposal ${e?.projectTitle}`
                                                                        ) : e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || e.action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? (
                                                                            `Confirmed information`
                                                                        ) : e.action === ACTIVITY_ENUM.PROJECT_EDITED ? (
                                                                            e?.projectUpdateLog?.updateLog ? `Edited ${Object.keys(e?.projectUpdateLog?.updateLog)?.map(key => key === 'Address2' ? 'Second Address' : key)} details` : 'Edited Project'
                                                                        ) : e.action === ACTIVITY_ENUM.PROPOSAL_CREATED ? (
                                                                            'Created Proposal'
                                                                        ) : e.action === ACTIVITY_ENUM.PROPOSAL_EDITED ? (
                                                                            'Edited Proposal'
                                                                        ) : e.action === ACTIVITY_ENUM.CREATED_BID ? (
                                                                            'Created Bid'
                                                                        ) : e.action === ACTIVITY_ENUM.UPDATED_BID ? (
                                                                            'Updated Bid'
                                                                        ) : e.action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? (
                                                                            'Uploaded Template'
                                                                        ) : e.action === ACTIVITY_ENUM.PROPOSAL_DONE ? (
                                                                            'Proposal Done'
                                                                        ) : e.action === ACTIVITY_ENUM.CREATED_ORDER ? (
                                                                            'Created Order'
                                                                        ) : e.action === ACTIVITY_ENUM.CREATED_SHIPPING_SU ? (
                                                                            'Created Shipping'
                                                                        ) : e.action === ACTIVITY_ENUM.PROJECT_JOINED ? (
                                                                            'Joined Project'
                                                                        ) : e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED ? (
                                                                            'Joined Company'
                                                                        ) : e.action === ACTIVITY_ENUM.USER_CREATED ? (
                                                                            'Signed up as a new ' + e.userType.toUpperCase()
                                                                        ) : e.action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? (
                                                                            `Invited ${e.userName} to join!`
                                                                        ) : e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? (
                                                                            'Approved Company'
                                                                        ) : e.action === ACTIVITY_ENUM.SHIPPING_DELIVERED ? (
                                                                            'Marked as delivered'
                                                                        ) : e.action === ACTIVITY_ENUM.BID_OPENED ? (
                                                                            'Opened bidding'
                                                                        ) : e.action === ACTIVITY_ENUM.BID_CLOSED ? (
                                                                            'Closed bidding'
                                                                        ) : e.action === ACTIVITY_ENUM.BID_ACCEPTED ? (
                                                                            'Approved bid'
                                                                        ) : e.action === ACTIVITY_ENUM.COMPANY_DECLINED_BY_ADMIN ? (
                                                                            'Declined Company'
                                                                        ) : e.action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY || e.action === ACTIVITY_ENUM.UPLOADED_FILE ? (
                                                                            'Uploaded a file'
                                                                        ) : e.action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE ? (
                                                                            'Requested product pricing'
                                                                        )  : e.action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU ? (
                                                                            'Send product pricing'
                                                                        ): e.action === ACTIVITY_ENUM.PROJECT_STATUS_CHANGED ? (
                                                                            `Changed project status to ${e?.project?.status}`
                                                                        ) : ('')
                                                                    }
                                                                </Text>
                                                                <Text textAlign='start' width='25%'>
                                                                    {moment(e.createdAt.date).format('hh:mm A')}
                                                                </Text>
                                                                <Text textAlign='start' width='25%' p={0}>
                                                                    <Button mr={10} rightIcon={(
                                                                        <RightArrow width="8px" height="8px"/>)}
                                                                            fontWeight='600' height="30px"
                                                                            disabled={!!loading}
                                                                            onClick={() =>
                                                                                e.action === ACTIVITY_ENUM.COMPANY_DECLINED_BY_ADMIN ? '' :
                                                                                e.action === ACTIVITY_ENUM.COMPANY_CREATED || e.action === ACTIVITY_ENUM.COMPANY_EDITED ? viewCompany(e)
                                                                                    // : e.action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? downloadFile(e)
                                                                                    : e.action === ACTIVITY_ENUM.PROPOSAL_DONE || ((e.action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || e.action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU) && user?.type===SUPERADMIN) ? history.push(`/projects/proposal/${e?.projectTitle}/${e?.project?.id}/${e?.actionId}`)
                                                                                    : e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN && user?.type === SUPERADMIN ? history.push(`/companies/${e?.actionId}`)
                                                                                        :  e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED || e.action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY ? history.push({
                                                                                                pathname: 'profile',
                                                                                                search: '?company=MyCompany'
                                                                                            })
                                                                                                : (e.action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || e.action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU) && user?.type === SU ? history.push(`/project-supplier/${e?.project?.id}`)
                                                                                            : e.action === ACTIVITY_ENUM.USER_CREATED || e.action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? history.push(`users/${e.actionId}`)
                                                                                                : (e.action === ACTIVITY_ENUM.SHIPPING_DELIVERED && e.userType === REU) ? history.push(`/orders/estimate/reu/${e?.estimateReu?.id}/3`)
                                                                                                    : (e.action === ACTIVITY_ENUM.SHIPPING_DELIVERED && e.userType === SU) ? history.push(`/orders/estimate/su/${e?.estimate?.id}/3`)
                                                                                                        : e.action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? downloadTemplate(e?.actionId)
                                                                                                            : e.action === ACTIVITY_ENUM.BID_OPENED || e.action === ACTIVITY_ENUM.BID_CLOSED || e.action === ACTIVITY_ENUM.BID_ACCEPTED || e.action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? history.push(`/project/${e.actionId}`)
                                                                                                                : (e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || e.action === ACTIVITY_ENUM.CREATED_ORDER || e.action === ACTIVITY_ENUM.CREATED_SHIPPING_SU || e.action === ACTIVITY_ENUM.SHIPPING_DELIVERED) && e.userType === REU ? history.push(`/orders/estimate/reu/${e?.estimateReu?.id}/1`)
                                                                                                                    : (e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED || e.action === ACTIVITY_ENUM.CREATED_ORDER || e.action === ACTIVITY_ENUM.CREATED_SHIPPING_SU) && e.userType === SU ? history.push(`/orders/estimate/su/${e?.estimate?.id}/1`)
                                                                                                                        : e.action === ACTIVITY_ENUM.UPLOADED_FILE ? history.push('/upload')
                                                                                                                            : viewProject(e)
                                                                            }
                                                                            color='#222222'
                                                                            width="150px"
                                                                            backgroundColor="#FFE68D">
                                                                        {
                                                                            e.action === ACTIVITY_ENUM.COMPANY_CREATED || e.action === ACTIVITY_ENUM.COMPANY_EDITED || e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED || e.action === ACTIVITY_ENUM.COMPANY_ACCEPTED_BY_ADMIN ? (
                                                                                'View Company'
                                                                            ) : e.action === ACTIVITY_ENUM.PROPOSAL_APPROVED_SU || e.action === ACTIVITY_ENUM.CREATED_ORDER || e.action === ACTIVITY_ENUM.EU_ESTIMATE_CREATED ? (
                                                                                'View Estimate'
                                                                            ) : e.action === ACTIVITY_ENUM.CREATED_SHIPPING_SU || e.action === ACTIVITY_ENUM.SHIPPING_DELIVERED ? (
                                                                                'View Shipping'
                                                                            ) : e.action === ACTIVITY_ENUM.USER_CREATED || e.action === ACTIVITY_ENUM.USER_INVITED_TO_PLATFORM ? (
                                                                                'View Profile'
                                                                            ) : e.action === ACTIVITY_ENUM.PROPOSAL_DONE || e.action === ACTIVITY_ENUM.CUSTOM_PRICE_INVITE || e.action === ACTIVITY_ENUM.CUSTOM_PRICE_UPDATE_SU ? (
                                                                                'View Proposal'
                                                                            ) : e.action === ACTIVITY_ENUM.BID_ACCEPTED ? (
                                                                                'View Details'
                                                                            ) : e.action === ACTIVITY_ENUM.UPLOADED_FILE || e.action === ACTIVITY_ENUM.UPLOAD_FILE_COMPANY ? (
                                                                                'View File'
                                                                            ) : e.action === ACTIVITY_ENUM.UPLOADED_TEMPLATE_EXEL ? (
                                                                                'View Template'
                                                                            ) : (
                                                                                'View Project'
                                                                            )
                                                                        }
                                                                    </Button>
                                                                </Text>
                                                            </Flex>
                                                        ))
                                                    )
                                                })()}
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                        ))
                    }
                </Box>
                <Box css={styles.paginator}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Pagination
                            limit={9}
                            otherPageColor='black'
                            offset={offset}
                            previousPageLabel={<LeftIcon fill='black'/>}
                            nextPageLabel={<RightIcon/>}
                            total={activityResponse?.data?.data['pagination']?.TotalItemCount}
                            onClick={(e, offset: number, page: number) => {
                                setOffset(offset)
                                setPage(page)
                            }}/>
                    </MuiThemeProvider>
                </Box>
            </Tabs>
        </>
    )
}

export default RecentActivity
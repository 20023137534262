import React, {useState, useEffect} from 'react'
import {Stack, HStack, Box, Flex, Checkbox, Heading, Text,} from '@chakra-ui/react'
import {ChakraSelectLabel, InputLabel} from '../../../../components/Theme'
import NavigateModal from './NavigateModal'
import Loading from "../../../Loading";
import {useGetCustomBids, useGetRecommended} from "../../../../core/hooks";
import {CONTROL_FILTER, PRODUCT_TYPES} from "../../../../constants/types";
import {SU} from "../../../../constants/user/userEnum";
import {ReactComponent as DollarIcon} from "../../../../assets/Icons/dollarIcon.svg";
import {ReactComponent as PendingIcon} from "../../../../assets/Icons/pendingIcon.svg";
import {ReactComponent as ArrowDownIcon} from "../../../../assets/downArrow.svg";

const Control = ({
                     onClose,
                     project,
                     luminaireId,
                     createKey,
                     setCreateKey,
                     fromAll,
                    reportId,
                     area,
                     luminaire,
                     control,
                     setControl,
                     setArea,
                     setRecent,
                     setType,
                     description,
                     customPricing,
                     setCustomPricing,
                 }) => {
    const [fetchedDataControl, setFetchedDataControl] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [controlAirtable, setControlAirtable] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filters, setFilters] = useState({
        type: '',
        manufacturer: '',
        model: '',
        productCode: '',
        depth: '',
        length: '',
        width: '',
        diameter: ''
    })
    const [customBids, setCustomBids] = useState([]);
    const {data, isLoading, refetch} = useGetRecommended(
        {
            condition: {
                table: PRODUCT_TYPES["4"],
                filter: CONTROL_FILTER(luminaire),
                projectId: project?.id,
            }
        }
    )
    const {data: dataCustomBids, isLoading: isLoadingBids, refetch: refetchBids} = useGetCustomBids(
        {
            condition: {
                type: 'report',
                projectId: project?.id,
                productType: 'control',
                reportId: reportId,
                userType: SU,
            }
        }
    )
    const validate = ({fields}) => {
        let bool = true
        // if (!!filters?.type && bool) {
        //     !!fields['Type'] ? bool = fields['Type'].toLowerCase().includes(filters.type.toLowerCase()) : bool = false
        // }
        if (!customPricing.control && bool){
            (!fields.hasOwnProperty('Price Per Unit ($)') || !fields['Price Per Unit ($)'] || Number(fields['Price Per Unit ($)']) == 0) ? bool = false : bool = true
        }
        if (customPricing.control && bool) {
            (!fields.hasOwnProperty('Price Per Unit ($)') || !fields['Price Per Unit ($)'] || Number(fields['Price Per Unit ($)']) == 0) ? bool = true : bool = false
        }
        if (!!filters?.manufacturer && bool) {
            !!fields['Manufacturer'] ? bool = fields['Manufacturer'].toLowerCase().includes(filters.manufacturer.toLowerCase()) : bool = false
        }
        if (!!filters?.model && bool) {
            !!fields['Variable 3'] ? bool = fields['Variable 3'].toLowerCase().includes(filters.model.toLowerCase()) : bool = false
        }
        if (!!filters?.productCode && bool) {
            !!fields['Product Code'] ? bool = fields['Product Code'].toLowerCase().includes(filters.productCode.toLowerCase()) : bool = false
        }
        if (!!filters?.depth && bool) {
            !!fields['Depth (inches)'] ? bool = fields['Depth (inches)'] == filters.depth : bool = false
        }
        if (!!filters?.width && bool) {
            !!fields['Width (inches)'] ? bool = fields['Width (inches)'] == filters.width : bool = false
        }
        if (!!filters?.length && bool) {
            !!fields['Length (inches)'] ? bool = fields['Length (inches)'] == filters.length : bool = false
        }
        if (!!filters?.diameter && bool) {
            !!fields['Diameter (inches)'] ? bool = fields['Diameter (inches)'] == filters.diameter : bool = false
        }
        return bool
    }


    const setLedsForAll = (arr) => {
        const groupedObjects = new Map();
        arr[0].forEach((obj) => {
            const {id, qty, fixtureMountHeight, globalQty, globalQtyLamp, ...rest} = obj;
            const key = JSON.stringify(rest);
            if (!groupedObjects.has(key)) {
                groupedObjects.set(key, [obj]);
            } else {
                groupedObjects.get(key).push(obj);
            }
        });

        const filteredGroups = Array.from(groupedObjects.values()).filter(
            (group) => group.length > 1
        );

        return filteredGroups.reduce((result, group) => result.concat(group), [])
    }

    const create = () => {
        if (control.controlRecomendedDescription.length === 0) return
        let index = 0
        let ides = setLedsForAll(area.map((e) => e?.luminaires))
        let newArray = []
        if (fromAll) {
            newArray = area?.map((e, i) => e?.luminaires.map((led) => {
                let newObj = {
                    ...led
                }
                if (ides.length > 0) {
                    for (let j = 0; j < ides.length; j++) {
                        let {id, qty, fixtureMountHeight, globalQty, globalQtyLamp, ...rest} = ides[j]
                        let {
                            id: targetId,
                            qty: targetQty,
                            fixtureMountHeight: targetHeight,
                            globalQty: targetGlobalQty,
                            globalQtyLamp: targetGlobalQtyLamp,
                            ...obj
                        } = newObj
                        let {globalQty: glob, globalQtyLamp: globLamp, ...lumin} = luminaire

                        if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                            if (ides[j].id === newObj.id) {
                                newObj = {
                                    ...newObj,
                                    ...control,
                                }
                                index = i
                            }
                        } else if (JSON.stringify(lumin) === JSON.stringify(obj)) {
                            newObj = {
                                ...newObj,
                                ...control,
                            }
                            index = i
                        }
                    }
                } else {
                    let {id, qty, fixtureMountHeight, globalQty, globalQtyLamp, ...rest} = newObj
                    let {globalQty: glob, globalQtyLamp: globLamp, ...lumin} = luminaire
                    if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                        newObj = {
                            ...newObj,
                            ...control,
                        }
                        index = i
                    }
                }

                return newObj
            }))
        } else {
            newArray = area?.map((e, i) => e?.luminaires.map((luminaire) => {
                let newObj = {
                    ...luminaire
                }
                if (newObj.id === luminaireId || ides.includes(newObj.id)) {
                    newObj = {
                        ...newObj,
                        ...control,
                    }
                    index = i
                }
                return newObj
            }))
        }
        area[index].luminaires = newArray[index];
        setArea(area)
        setRecent([...newArray])
    }


    useEffect(() => {
        if (createKey === true) {
            create()
            setCreateKey(false)
            onClose()
        }
    },[createKey])

    const handleSelect = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fetchedDataControl?.filter((item) => validate(item) === true).map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (!data) return
        setFetchedDataControl(data.data.data)
        setSelectedItems(control.hasOwnProperty('controlRecomendedCustomPriceIdes') ? control.controlRecomendedCustomPriceIdes : [])
        setControlAirtable(control.hasOwnProperty('controlRecomendedAirtableArray') ? control.controlRecomendedAirtableArray : [])
        if (dataCustomBids){
            setCustomBids(dataCustomBids.data.data)
        }
        data.data.data.forEach(item1 => {
            item1.fields['Bid Status'] = 'review'
            if (dataCustomBids){
                dataCustomBids.data.data.forEach(item2 => {
                    if (item1.id === item2.fields['Product ID']) {
                        if (item2.fields['SU PPU ($)']){
                            item1.fields['SU PPU ($) Custom'] = item2.fields['SU PPU ($)'];
                            item1.fields['SU PPU ($) Custom FE'] = item2.fields['FE PPU ($)'];
                            item1.fields['Bid Status'] = 'accepted'
                        } else {
                            item1.fields['Bid Status'] = 'pending'
                        }
                    }
                });
            }
        });
    }, [data, dataCustomBids])


    useEffect(() => {
        if (selectedItems.length > 0) {
            if (customPricing.control) {
                setControl({
                    ...control,
                    controlRecomendedCustomPriceIdes: selectedItems,
                    fixtureRecomendedAirtable: controlAirtable?.length > 0 && JSON.stringify(controlAirtable[0].fields),
                })
            } else {
                setControl({
                    ...control,
                    controlRecomendedCustomPriceIdes: [],
                })
            }
        } else {
            setControl({
                ...control,
                controlRecomendedCustomPriceIdes: [],
            })
        }
    }, [selectedItems])

    useEffect(() => {
        if (controlAirtable.length > 0) {
            setControl({
                ...control,
                controlRecomendedAirtable: JSON.stringify(controlAirtable[0].fields),
                controlRecomendedAirtableArray: controlAirtable,
                controlRecomendedDescription: controlAirtable[0].fields['FE Product Description']
            })
        } else {
            setControl({
                ...control,
                controlRecomendedAirtable: [],
                controlRecomendedAirtableArray: [],
                controlRecomendedDescription: '',
            })
        }
    }, [controlAirtable])

    if (isLoading) return <Loading height='100%'/>

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('power')
                }}
                rightAction={() => {
                    create()
                    setType('accessory')
                }}
                left="Power Supply"
                right="Accessories"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    control details
                </Box>
            </HStack>
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel
                    height='46px'
                    maxW="80px" type="number"
                    value={control?.controlRecomendedQty}
                    onChange={(e) => setControl({...control, controlRecomendedQty: e.target.value})}
                    pattern="[0-9]*" label="QTY"/>
                <Box position='relative' width='100%' height='46px'>
                    <Flex
                        alignItems='center'
                        onClick={() => setIsOpen(!isOpen)}
                        bg='#EEEEEE'
                        cursor='pointer'
                        border='1px solid #ccc'
                        borderBottom='none'
                        borderBottomLeftRadius='6px'
                        position='relative'
                        borderBottomRightRadius='6px'
                        fontSize='18px'
                        p='10px 30px'>
                        {controlAirtable?.length > 0 ? controlAirtable.map(item => item.fields["FE Product Description"]).join(', ') : "No Change"}
                        <ArrowDownIcon style={{position: 'absolute', right: '10px', top: '18px'}}/>
                    </Flex>
                    {isOpen && (
                        <ul style={{
                            position: 'absolute',
                            top: '99%',
                            left: '0',
                            right: '0',
                            border: '1px solid #ccc',
                            margin: 0,
                            padding: 0,
                            listStyle: 'none',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            maxHeight: '500px',
                            overflowY: 'auto'
                        }}>
                            {customPricing.control && (
                                <li style={{
                                    padding: '8px 8px 0 8px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        _checked={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _hover={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _focus={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                borderColor: 'black',
                                                boxShadow: 'none'
                                            }
                                        }}
                                        _focusVisible={{
                                            "& .chakra-checkbox__control": {outline: 'none', boxShadow: 'none'}
                                        }}
                                        size='lg'
                                        iconColor='black'
                                        isChecked={selectAll}
                                        mr='8px'
                                        borderColor='#222222'
                                        onChange={handleSelectAll}>
                                        <Heading m={0} fontSize='14px'>Select All</Heading>
                                    </Checkbox>
                                </li>
                            )}
                            {fetchedDataControl?.filter((item) => validate(item) === true).map(item => (
                                <li key={item.id} style={{
                                    padding: customPricing.control ? '8px 8px 0 8px' : '8px 30px 0 30px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    {
                                        customPricing.control ? (
                                            <>
                                                {item.fields['Bid Status'] === 'pending' || item.fields['Bid Status'] === 'accepted' ? (
                                                    <Flex
                                                        mr='8px'
                                                        border='2px solid black'
                                                        alignItems='center' justifyContent='center' borderRadius='2px'
                                                        bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                                        width='20px' height='20px' >
                                                        {item.fields['Bid Status'] === 'pending' ? (
                                                            <PendingIcon />
                                                        ) : (
                                                            <DollarIcon />
                                                        )}
                                                    </Flex>
                                                ) :(
                                                    <Checkbox
                                                        _checked={{
                                                            "& .chakra-checkbox__control": {
                                                                background: "#FFE68D",
                                                                boxShadow: 'none',
                                                                borderColor: 'black'
                                                            }
                                                        }}
                                                        _hover={{
                                                            "& .chakra-checkbox__control": {
                                                                background: "#FFE68D",
                                                                boxShadow: 'none',
                                                                borderColor: 'black'
                                                            }
                                                        }}
                                                        size='lg'
                                                        iconColor='black'
                                                        borderColor='#222222'
                                                        isChecked={selectedItems.includes(item.id)}
                                                        onChange={() => handleSelect(item.id)}
                                                        mr='8px'>
                                                    </Checkbox>
                                                )}
                                                <Text as='span'
                                                      onClick={(e) => {
                                                          setControlAirtable((prev) => {
                                                              if (prev.some(control => control.id === item.id)) {
                                                                  return prev.filter(control => control.id !== item.id);
                                                              } else {
                                                                  return [item];
                                                              }
                                                          })
                                                      }}
                                                      fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                <Text as='span' onClick={(e) => setControlAirtable([item])}
                                                      fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$'+item.fields["SU PPU ($) Custom"] : ''}</Text>
                                            </>
                                        ) : (
                                            <>
                                                <Text
                                                    onClick={(e) => {
                                                        setControlAirtable((prev) => {
                                                            if (prev.some(control => control.id === item.id)) {
                                                                return prev.filter(control => control.id !== item.id);
                                                            } else {
                                                                return [item];
                                                            }
                                                        })
                                                    }}
                                                    as='span'
                                                    fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                <Text as='span'
                                                      fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$'+item.fields["SU PPU ($) Custom"] : item.fields["Price Per Unit ($)"]}</Text>
                                            </>
                                        )
                                    }
                                    <Flex
                                        display={customPricing.control && (!item.fields["Price Per Unit ($)"] || item.fields["Price Per Unit ($)"] === '$0.00') ? 'flex' : 'none'}
                                        alignItems='center' justifyContent='center' borderRadius='2px'
                                        bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                        width='20px' height='20px' position='absolute' right='30px' top='8px'>
                                        {item.fields['Bid Status'] === 'pending' ? (
                                            <PendingIcon />
                                        ) : item.fields['Bid Status'] === 'accepted' ? (
                                            <DollarIcon />
                                        ) :(
                                            '?'
                                        )}
                                    </Flex>
                                </li>
                            ))}
                        </ul>
                    )}
                </Box>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel
                    height='46px'
                    label="Type:"
                    placeholder="Select"
                    value={control?.controlRecomendedType}
                    onChange={(e) => {
                        setFilters({...filters, type: e.target.value})
                        setControl({...control, controlRecomendedType: e.target.value})
                    }}>
                    <option value="Wall Switch">Wall Switch</option>
                    <option value="Timer">Timer</option>
                    <option value="Occupancy Sensor">Occupancy Sensor</option>
                    <option value="Daylight Sensor">Daylight Sensor</option>
                </ChakraSelectLabel>
                <ChakraSelectLabel
                    height='46px'
                    label="Manufacturer:"
                    placeholder="Select"
                    value={control?.controlRecomendedManufacturer}
                    onChange={(e) => {
                        setFilters({...filters, manufacturer: e.target.value})
                        setControl({...control, controlRecomendedManufacturer: e.target.value})
                    }}>
                </ChakraSelectLabel>
                <ChakraSelectLabel
                    height='46px'
                    label="Model:"
                    placeholder="Select"
                    value={control?.controlRecomendedModel}
                    onChange={(e) => {
                        setFilters({...filters, model: e.target.value})
                        setControl({...control, controlRecomendedModel: e.target.value})
                    }}>
                </ChakraSelectLabel>
                <InputLabel
                    height='46px'
                    value={control?.controlRecomendedProductCode}
                    onChange={(e) => {
                        setFilters({...filters, productCode: e.target.value})
                        setControl({...control, controlRecomendedProductCode: e.target.value})
                    }}
                    label="Product Code:"/>
            </HStack>
            <HStack spacing="5">
                <InputLabel
                    height='46px' type="number" pattern="[0-9]*" value={filters?.length}
                    onChange={(e) => {
                        setFilters({...filters, length: e.target.value})
                    }}
                    label="Dimensions-Length (in):"/>
                <InputLabel
                    height='46px' type="number" pattern="[0-9]*" value={filters?.width}
                    onChange={(e) => {
                        setFilters({...filters, width: e.target.value})
                    }}
                    label="Dimensions-Width (in):"/>
                <InputLabel
                    height='46px' type="number" value={filters?.depth} label="Dimensions-Depth (in):"
                    onChange={(e) => {
                        setFilters({...filters, depth: e.target.value})
                    }}/>
                <InputLabel
                    height='46px' type="number" value={filters?.diameter}
                    onChange={(e) => {
                        setFilters({...filters, diameter: e.target.value})
                    }}
                    label="Dimensions-Diameter (in):"/>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <HStack spacing='30px'>
                    <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                        Show Custom <br/>Price Options
                    </Heading>
                    <Checkbox
                        _checked={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        _hover={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        iconColor='black'
                        onChange={(e) => {
                            refetch()
                            if (!e.target.isChecked) {
                                setSelectedItems([])
                                setControlAirtable([])
                            }
                            setCustomPricing({...customPricing, control: !customPricing.control})
                        }}
                        isChecked={customPricing.control} color='#A9A9A9' width='20px' height='20px'
                        borderColor='#2C628D'>
                        Yes
                    </Checkbox>
                </HStack>
            </HStack>
        </Stack>
    )
}

export default Control

import {Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input, Flex, Image, VStack, HStack, GridItem, SimpleGrid, Heading, Grid,} from '@chakra-ui/react'
import {S3_BUCKET} from '../../constants'
import AreaInput from './AreaInput'
import AccordionSolo from './AccordionSolo/index'

const Area = ({idx, areas, areaName, recent,reportId,customPricing, setCustomPricing, setRecent, area,project, setArea, data,}) => {
    if (areaName && areaName !== data.title) return <></>
    return (
        <>
            <Accordion allowToggle key={idx}>
                <AccordionItem borderRadius='14px' border='1px solid #9CBDD8' position="relative">
                    <Flex px="4" alignItems="center" justifyContent="space-between" borderRadius="10px" height="57px" bg="#2C628D" flex="1">
                        <Flex alignItems="center">
                            <Input readOnly color="white" fontWeight='500' fontSize='32px' variant="unstyled" value={data.title} maxW="150px"/>
                        </Flex>
                        <Flex alignItems="center">
                            <AccordionButton borderRadius="3px" border="2px solid" borderColor="white" fontSize="sm" color="white" height="30px" maxW="120px">
                                Area Details
                                <AccordionIcon/>
                            </AccordionButton>
                        </Flex>
                    </Flex>
                    <AccordionPanel pb={10} pt={7}>
                        <Flex flexDirection="column">
                            <HStack spacing={10}>
                                <VStack>
                                    <Image
                                        src={data?.image ? `${S3_BUCKET}${data.image}` : ' '}
                                        height="298px"
                                        width="341px"/>
                                </VStack>
                                <AreaInput
                                    areas={areas}
                                    idx={idx}/>
                            </HStack>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Grid templateColumns='repeat(2, 1fr)' gap={6} mb={6} mt={10}>
                <Heading borderBottomWidth="1px" borderColor="grey.200" m={0} color="#A8A8A8" textAlign='center' fontSize="24px">
                    EXISTING
                </Heading>
                <Heading borderBottomWidth="1px" borderColor="#8FCF74" m={0} color="#8FCF74" textAlign='center' fontSize="24px">
                    RECOMENDED
                </Heading>
            </Grid>
            <Flex justifyContent='space-between'>
                <Grid w='full' templateColumns="repeat(3, 1fr)" px={5}>
                    <Heading  as="h4" fontSize="14px">QTY</Heading>
                    <Heading  as="h4" fontSize="14px">LUMINAIRE DESCRIPTION</Heading>
                    <Heading  as="h4" textAlign='end' fontSize="14px">MOUNT HEIGHT</Heading>
                </Grid>
                <Grid w='full' templateColumns="repeat(3, 1fr)" px={5}>
                    <Heading  as="h4" fontSize="14px">QTY</Heading>
                    <Heading  as="h4" fontSize="14px">LUMINAIRE DESCRIPTION</Heading>
                    <Heading  textAlign='end' as="h4" fontSize="14px">MOUNT HEIGHT</Heading>
                </Grid>
            </Flex>
            <SimpleGrid  w="full" columns={{base: 1}}>
                {area?.filter((e) => e.title === areaName)[0].luminaires?.map((luminaire) => (
                        <GridItem key={luminaire.id}>
                            <AccordionSolo
                                reportId={reportId}
                                customPricing={customPricing}
                                setCustomPricing={setCustomPricing}
                                project={project}
                                fromAll={false}
                                setRecent={setRecent}
                                recent={recent}
                                luminaire={luminaire}
                                area={area}
                                setArea={setArea}/>
                        </GridItem>
                    ))}
            </SimpleGrid>
        </>
    )
}

export default Area